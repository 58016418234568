import { Component, Input, ViewEncapsulation, TemplateRef } from '@angular/core';
import { TreeNode } from '../models/tree-node.model';


export class TreeNodeContent {
   node: TreeNode;
   index: number;
   template: TemplateRef<any>;
static decorators: DecoratorInvocation[] = [
{ type: Component, args: [{
  selector: 'tree-node-content',
  encapsulation: ViewEncapsulation.None,
  template: `
  <span *ngIf="!template">{{ node.displayField }}</span>
  <ng-container
    [ngTemplateOutlet]="template"
    [ngOutletContext]="{ $implicit: node, node: node, index: index }">
  </ng-container>`,
}, ] },
];
/** @nocollapse */
static ctorParameters: () => ({type: any, decorators?: DecoratorInvocation[]}|null)[] = () => [
];
static propDecorators: {[key: string]: DecoratorInvocation[]} = {
'node': [{ type: Input },],
'index': [{ type: Input },],
'template': [{ type: Input },],
};
}

interface DecoratorInvocation {
  type: Function;
  args?: any[];
}
